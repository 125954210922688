<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" align-self="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="mr-1"
                color="grey darken-3"
                :to="{
                  name: `${page_route}${!id ? '' : '.view'}`,
                  params: { id: id },
                }"
              >
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
            </template>
            <span v-text="'Atrás'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="data_form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="30"
                      type="text"
                      label="Nombre"
                      v-model="data.name"
                      :rules="rules.required"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  small
                  v-on="on"
                  class="ml-1"
                  :loading="loading"
                  :color="!id ? 'success' : 'info'"
                  @click.prevent="handleSubmit"
                >
                  <v-icon>
                    {{ !id ? "mdi-plus" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-text="!id ? 'Crear' : 'Editar'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  toFormData,
} from "../../control";

export default {
  components: {},

  data() {
    return {
      page_route: "insured_categories",
      api: "insureds/categories",
      login: this.$store.getters.getLogin,
      loading: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      data: {
        id: null,
        active: true,
        name: "",
      },
      rules: rules(),
    };
  },

  methods: {
    handleSubmit() {
      if (this.$refs.data_form.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${!this.id ? "creación" : "edición"} del registro?`
            )
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;

              let data_form = toFormData(this.data);

              if (this.id) {
                data_form.append("_method", "PATCH");
              }

              Axios.post(
                URL_API + "/" + this.api + (this.id ? "/" + this.id : ""),
                data_form,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                res.data.success
                  ? this.$router.push({
                      name: `${this.page_route}${!this.id ? "" : ".view"}`,
                      params: { id: this.id },
                    })
                  : console.log(res.data.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },

  mounted() {
    Axios.get(
      `${URL_API}/catalog?name=faq_interfaces&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faq_interfaces = resp.data.data;
      this.faq_interfaces_ldg = false;
    });

    if (this.id) {
      this.loading = true;

      Axios.get(
        `${URL_API}/${this.api}/${this.id}`,
        headersToken(this.login.token)
      ).then((res) => {
        this.data = res.data.data;
        this.loading = false;
      });
    }
  },
};
</script>