var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","dark":"","x-small":"","color":"grey darken-3","to":{
                name: ("" + _vm.page_route + (!_vm.id ? '' : '.view')),
                params: { id: _vm.id },
              }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Atrás')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"data_form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"text-caption",domProps:{"textContent":_vm._s('GENERAL')}})]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","counter":"","maxlength":"30","type":"text","label":"Nombre","rules":_vm.rules.required},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1)],1)],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","small":"","loading":_vm.loading,"color":!_vm.id ? 'success' : 'info'},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(!_vm.id ? "mdi-plus" : "mdi-pencil")+" ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(!_vm.id ? 'Crear' : 'Editar')}})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }